var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('swiper', {
    staticClass: "swiper",
    attrs: {
      "options": _vm.swiperOption
    }
  }, [_c('swiper-slide', [_c('div', {
    staticClass: "slide",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "text",
    class: _vm.$mq
  }, [_vm._v(" “Оптимальный инструмент внешней аналитики на сегодняшний день. Сервис дает полную картину и отлично отслеживает данные продаж не только на Wildberries, но и на OZON. Перед запуском новых продуктов всегда обращаюсь к данным SalesFinder.” ")]), _c('div', {
    staticClass: "avatar"
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/reviews/1.jpg`)
    }
  })]), _c('div', {
    staticClass: "name"
  }, [_vm._v("Андрей Старков")]), _c('div', {
    staticClass: "person"
  }, [_vm._v(" Создатель бренда Advanced Nutrition и владелец сети магазинов Planka ")])])]), _c('swiper-slide', [_c('div', {
    staticClass: "slide",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "text",
    class: _vm.$mq
  }, [_vm._v(" \"Благодаря вашему сервису мне удалось найти немало ликвидных товаров и грамотно произвести аналитику конкурентов 🫶🏻” ")]), _c('div', {
    staticClass: "avatar"
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/reviews/2.jpg`)
    }
  })]), _c('div', {
    staticClass: "name"
  }, [_vm._v("Сысолятина Виктория")]), _c('div', {
    staticClass: "person"
  }, [_vm._v(" Начинающий селлер в категории Аксессуары для электроники ")])])]), _c('swiper-slide', [_c('div', {
    staticClass: "slide",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "text",
    class: _vm.$mq
  }, [_vm._v(" \"Сервис предоставляет глубокую аналитику по различным категориям товаров и позволяет гибко настраивать фильтры, включая такие параметры, как вес товара. Я рекомендую его всем продавцам и селлерам, работающим на Ozon и Wildberries. Я считаю, что это лучший сервис для работы с маркетплейсами.” ")]), _c('div', {
    staticClass: "avatar"
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/reviews/3.jpg`)
    }
  })]), _c('div', {
    staticClass: "name"
  }, [_vm._v("Алимов Хамид")]), _c('div', {
    staticClass: "person"
  }, [_vm._v("Кросс-категорийный селлер")])])]), _c('swiper-slide', [_c('div', {
    staticClass: "slide",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "text",
    class: _vm.$mq
  }, [_vm._v(" \"SalesFinder выбрала исходя из соотношения цена/качество, предоставляемых данных мне хватает для работы. Так же хочу отметить супер оперативную и внимательную работу службы поддержки. Второй раз обращаюсь за помощью - и получаю ее в полном объеме, никакого переключения на ИИ и шаблонных ответов, действительно служба поддержки пользователей на уровне! Могу смело рекомендовать SalesFinder своим коллегам селлерам.” ")]), _c('div', {
    staticClass: "avatar"
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/reviews/4.jpg`)
    }
  })]), _c('div', {
    staticClass: "name"
  }, [_vm._v("Липина Олеся")]), _c('div', {
    staticClass: "person"
  }, [_vm._v(" Опытный селлер в категории Мелкая бытовая техника ")])])]), _c('swiper-slide', [_c('div', {
    staticClass: "slide",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "text",
    class: _vm.$mq
  }, [_vm._v(" \"Этот сервис значительно помог мне увеличить оборот компаний, с которыми я работаю. Благодаря ему, я научился правильно выбирать категорию для товара и оптимизировать его стоимость, что очень важно для успешного ведения бизнеса на маркетплейсах. Я рекомендую SalesFinder всем, кто только начинает осваивать маркетплейсы, а также тем, кто уже имеет опыт в этой сфере. Этот сервис станет вашим незаменимым помощником в развитии онлайн-бизнеса.\" ")]), _c('div', {
    staticClass: "avatar"
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/reviews/5.jpg`)
    }
  })]), _c('div', {
    staticClass: "name"
  }, [_vm._v("Виноградов Николай")]), _c('div', {
    staticClass: "person"
  }, [_vm._v(" Селлер в категориях: Одежда, Компьютерные комплектующие, Товары для дома ")])])]), _c('swiper-slide', [_c('div', {
    staticClass: "slide",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "text",
    class: _vm.$mq
  }, [_vm._v(" \"Используя этот сервис, я подобрал товар для теплого сезона и заработал на нем 200 000 рублей. Таким образом, подписка окупилась всего за пять месяцев на одном артикуле. В итоге я пользуюсь SalesFinder уже год и продлил подписку еще на полтора года.\" ")]), _c('div', {
    staticClass: "avatar"
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/reviews/6.jpg`)
    }
  })]), _c('div', {
    staticClass: "name"
  }, [_vm._v("Жеребцов Илья")]), _c('div', {
    staticClass: "person"
  }, [_vm._v(" HANSA SHOP: Автотовары, Аксессуары для электроники, Аксессуары для туризма ")])])]), _c('div', {
    staticClass: "swiper-button-prev-custom",
    class: _vm.$mq,
    attrs: {
      "slot": "button-prev"
    },
    slot: "button-prev"
  }, [_c('svg', {
    attrs: {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M16 9H2M2 9L9 2M2 9L9 16",
      "stroke": "#5F5CDD",
      "stroke-width": "3",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })])]), _c('div', {
    staticClass: "swiper-button-next-custom",
    class: _vm.$mq,
    attrs: {
      "slot": "button-next"
    },
    slot: "button-next"
  }, [_c('svg', {
    attrs: {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M2 9H16M16 9L9 2M16 9L9 16",
      "stroke": "#5F5CDD",
      "stroke-width": "3",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };